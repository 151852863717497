import React from 'react'
import { withFormik, Form, Field } from 'formik'

/*const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}*/

/** constants */
const formName = 'investors'
const wrapperClass = 'pb-4 lg:pb-4'
const msgSuccess = 'Thank you for registering your interest in investing with Puro. We will be in touch very soon!'
const msgError = 'There was an error submitting the form, please try again.'

const Success = () => (
  <div className={wrapperClass} id="thanks">
    <div className="grid my-6">
      <div className="grid__full text-center">
        {/* Success Message */}
        <div className="mt-2 p-5 bg-white rounded"><div>{msgSuccess}</div></div>
      </div>
    </div>
  </div>
)

const FormWithError = ({ isSubmitting, errors, touched, values }) => (
  <Form name={formName} className={wrapperClass}>
    <div className="grid">
      <div className="grid__full">
        <div className="flex justify-between -mr-2 lg:mr-0 pb-2 lg:pb-4">
          <div className="w-1/2 lg:w-11/23 pr-2 lg:pr-0 pb-2 lg:pb-0">
            <Field
              id="firstName"
              name="firstName"
              type="text"
              className="w-full px-2 py-3"
              placeholder="First name*"
            />
            {((touched.firstName && errors.firstName) ? (<div className="mt-2 text-red">{errors.firstName}</div>) : null)}
          </div>
          <div className="w-1/2 lg:w-11/23 pr-2 lg:pr-0 pb-2 lg:pb-0">
            <Field
              id="lastName"
              name="lastName"
              type="text"
              className="w-full px-2 py-3"
              placeholder="Last name*"
              
            />
              {((touched.lastName && errors.lastName) ? (<div className="mt-2 text-red">{errors.lastName}</div>) : null)}
          </div>
        </div>
        {/* Email */}
        <div className="lg:flex justify-between pb-4">
          <div className="lg:w-full pb-2 lg:pb-0">
            <Field
              id="email"
              name="email"
              className="w-full px-2 py-3"
              placeholder="Email address*"
              
            />
            {((touched.email && errors.email) ? (<div className="mt-2 text-red">{errors.email}</div>) : null)}
          </div>
        </div>
        {/* Phone */}
        <div className="lg:flex justify-between pb-4">
          <div className="lg:w-full pb-2 lg:pb-0">
            <Field
              id="phone"
              name="phone"
              type="text"
              className="w-full px-2 py-3"
              placeholder="Phone Number*"
              
            />
            {((touched.phone && errors.phone) ? (<div className="mt-2 text-red">{errors.phone}</div>) : null)}
          </div>
        </div>
        {/* Investment Amount */}
        <div className="lg:flex justify-between lg:pb-5">
          <div className="lg:w-full pb-2 lg:pb-0">
            <Field component="select"
              id="investmentAmount"
              name="investmentAmount"
              type="dropdown"
              className={values.investmentAmount !== '' ? 'w-full px-2 py-3 contact-dropdown-filled text-charcoal' : 'w-full px-2 py-3 contact-dropdown-placeholder'}
              placeholder="Investment Amount*"
              >
              <option value="" defaultValue>Investment Amount</option>
              <option value="$2,500 - $9,999">$2,500 - $9,999</option>
              <option value="$10,000 - $49,999">$10,000 - $49,999</option>
              <option value="$50,000 - $199,999">$50,000 - $199,999</option>
              <option value="$200,000 +">$200,000 +</option>
            </Field>
          </div>
        </div>
      </div>
    </div>
    <div className="grid text-center">
      <div className="grid__full">
        <input
          className="lg:type-xl link bg-yellow px-8 py-2 hover:bg-yellow-hover rounded mt-6"
          type="submit"
          values="Submit"
          disabled={isSubmitting}
        />
        <p className="mb-4 mt-8 text-sm mb-12">
          <strong>Note:</strong> By submitting your details you give permission for Puro to send updates direct to your inbox.
        </p>
        {/* Error Message */}
        {errors.submit && <div className="text-white mt-4">{msgError}</div>}
      </div>
    </div>
  </Form>
)

const DefaultForm = props => {
  const { status } = props
  if (status && status.success) {
    return <Success />
  } else {
    return <FormWithError {...props} />
  }
}

const FormikForm = withFormik({
  mapPropsToValues: values => ({
    firstName: values.firstName || '',
    lastName: values.lastName || '',
    email: values.email || '',
    phone: values.phone || '',
    investmentAmount: values.investmentAmount || '',
  }),
  handleSubmit: async  (
    form,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      
      
      const apiUrl = 'https://puro.co.nz/.netlify/functions/campaign-monitor';      
      //const apiUrl = 'http://localhost:21189/.netlify/functions/campaign-monitor';      
            
      let data = await(JSON.stringify({name: form.firstName + ' ' + form.lastName, emailAddress: form.email, phone: form.phone, Possibleinvestment: form.investmentAmount, list: 'wait-list'}));      

      await(fetch(apiUrl, {method:'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
      },
        body: data
      }))
      .then(response => response.json());

      resetForm()
      setStatus({ success: true })
      
    } catch (error) {
      // Set errors.submit to TRUE
      setErrors({
        submit: true,
      })
      // REset error.submit to FALSE
      setTimeout(() => {
        setErrors({ submit: false })
      }, 5000)
    } finally {
      setSubmitting(false)
      let el = document.getElementById('thanks')
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  },
  validate: (values, props) => {
    const errors = {};

    if (!values.firstName) {
      errors.firstName = 'First Name is Required';
    }

    if (!values.lastName) {
      errors.lastName = 'Last Name is Required';
    }

    if (!values.phone) {
      errors.phone = 'Phone Number is Required';
    }

    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
       
    return errors;
  }
})(DefaultForm)

export default FormikForm
