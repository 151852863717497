import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import Form from '../components/Form/Investors'


const JoinUsPage = ({ data, location }) => {
  const page = data.prismicJoinUs.data
  return (
    <Layout>
      <SEO
        title={page.meta_title}
        description={page.meta_description}
        location={location}
      />
      <div className="pt-32 lg:pt-36 mb-6 lg:mb-16">
        <div className="relative">
          <div className="hidden lg:block absolute pin-t pin-l w-1/2 h-full pl-1/48">
            <div className="sticker">
              <h1 className="type-lg lg:type-3xl text-white">{page.join_us_title.text}</h1>
            </div>
          </div>
          <div className="grid">
            <div className="grid__half--primary lg:hidden">
              <h1 className="type-lg lg:type-3xl text-white">{page.join_us_title.text}</h1>
            </div>
            <div className="grid__half--secondary rte pt-4">
              <div dangerouslySetInnerHTML={{ __html: page.join_us_body.html }} />        
            </div>
          </div>
        </div>
      </div>
      <div className="mb-0 lg:mb-10" id="invest">
        <div className="relative">
          <div className="hidden lg:block absolute pin-t pin-l w-1/2 h-full pl-1/48">
            <div className="sticker">
              <h1 className="type-lg lg:type-3xl text-white">{page.invest_title.text}</h1>
            </div>
          </div>
          <div className="grid">
            <div className="grid__half--primary lg:hidden">
              <h1 className="type-lg lg:type-3xl text-white">{page.invest_title.text}</h1>
            </div>
            <div className="grid__half--secondary rte pt-4">
              {page.invest_media.url !== '' &&
                <video className="focus:outline-none pb-8" controls={true} poster={page.invest_media_poster.url}>
                  <source src={page.invest_media.url}></source>
                </video>
              }
              <div dangerouslySetInnerHTML={{ __html: page.invest_body.html }} />
              <div className="mt-10 mb-4">
                <Form/>
              </div>
              <div dangerouslySetInnerHTML={{ __html: page.invest_below_form_body.html }} />
              
            </div>
          </div>
        </div>
      </div>
      <div className="mb-0 lg:mb-16" id="buy">
        <div className="relative">
          <div className="hidden lg:block absolute pin-t pin-l w-1/2 h-full pl-1/48">
            <div className="sticker">
              <h1 className="type-lg lg:type-3xl text-white">{page.buy_title.text}</h1>
            </div>
          </div>
          <div className="grid">
            <div className="grid__half--primary lg:hidden">
              <h1 className="type-lg lg:type-3xl text-white">{page.buy_title.text}</h1>
            </div>
            <div className="grid__half--secondary pt-4">
              {page.invest_media.url !== '' &&
                <img className="mb-8" src={page.buy_image.url} alt={page.buy_image.alt}/>
              }
              <div className="rte" dangerouslySetInnerHTML={{ __html: page.buy_body.html }} />              
            </div>
          </div>
        </div>
      </div>
      <div className="mb-0 lg:mb-0 mt-6" id="work-for-us">
        <div className="relative">
          <div className="hidden lg:block absolute pin-t pin-l w-1/2 h-full pl-1/48">
            <div className="sticker">
              <h1 className="type-lg lg:type-3xl text-white">{page.work_for_us_title.text}</h1>
            </div>
          </div>
          <div className="grid">
            <div className="grid__half--primary lg:hidden">
              <h1 className="type-lg lg:type-3xl text-white">{page.work_for_us_title.text}</h1>
            </div>
            <div className="grid__half--secondary pt-4">
              {page.invest_media.url !== '' &&
                <img className="mb-8" src={page.work_for_us_image.url} alt={page.work_for_us_image.alt} />
              }
              <div className="rte" dangerouslySetInnerHTML={{ __html: page.work_for_us_body.html }} />              
            </div>
          </div>
        </div>
      </div>
      

    </Layout>
    
  )
}

JoinUsPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
}

export const pageJoinUsQuery = graphql`
  query {
    prismicJoinUs{
      data{
        join_us_title{
          text
        }
        join_us_body{
          html
        }
        invest_title{
          text
        }
        invest_media{
          url
        }
        invest_media_poster{
          url
        }
        invest_body{
          html
        }
        invest_below_form_body{
          html
        }
        buy_title{
          text
        }
        buy_image{
          url
          alt
        }
        buy_body{
          html
        }
        work_for_us_title{
          text
        }
        work_for_us_image{
          url
          alt
        }
        work_for_us_body{
          html
        }
        meta_title
        meta_description
      }
    }
  }
`

export default JoinUsPage
